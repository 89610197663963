/* eslint-disable */

import { useState, useEffect } from "react";
import { SERVER_URL, ADMIN_API } from "../../Config";
import ResponsiveTable from "../partials/ResponsiveTable";
import Loader from "../partials/Loader";
import DownloadCSV from "../partials/ExportToCsv";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";

import CreateApiClient from "../../API";

const api = CreateApiClient();

export default function Participants(props) {
  const [participants, setParticipants] = useState([]);
  const [submissions, setSubmissions] = useState([]);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [bonusLoading, setBonusLoading] = useState(null);
  const [bonus, setBonus] = useState(0);
  const [bonusPlayer, setBonusPlayer] = useState("");
  const [removeLoading, setRemoveLoading] = useState(null);
  const [value, setValue] = useState("");

  const onChangeBonus = (e, name) => {
    setBonus(e.target.value);
    setBonusPlayer(name);
  };

  useEffect(() => {
    getParticipants();
  }, [page]);

  const getParticipants = async (initialSearch, clear) => {
    setPage(page);
    setLoading(true);

    const url = `${SERVER_URL}/${ADMIN_API}/participants?id=${
      props.hunt.hunt._id
    }&page=${initialSearch ? 1 : page}&q=${clear ? "" : value}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.fetchData("GET", url, headers, null);
    setParticipants(data.players);
    setSubmissions(data.submissions);
    setTotalPages(data.total_pages);
    setPage(page);
    setLoading(false);
  };

  const addBonus = async (player, bonus) => {
    setBonusLoading(player.name);
    const url = `${SERVER_URL}/${ADMIN_API}/bonus?id=${props.hunt.hunt._id}&bonus=${bonus}&name=${player.name}&type=${player.type}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.fetchData("POST", url, headers, null);
    getParticipants(true);
    setBonusLoading(null);
    setBonus(0);
    setBonusPlayer("");
  };

  const removeParticipant = async (player) => {
    setRemoveLoading(player._id);
    const url = `${SERVER_URL}/${ADMIN_API}/delete-participant?playerId=${player._id}&huntId=${props.hunt.hunt._id}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.updateData("POST", url, headers, null);
    setRemoveLoading(null);
    getParticipants(true);
  };

  const tableHeaders = [
    "Rank",
    "Name",
    "Type",
    "Members",
    "Progress",
    "Score",
    "Bonus",
  ];

  const handlePrevClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextClick = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePageClick = (pageNum) => {
    setPage(pageNum);
  };

  const searchUser = async () => {
    setPage(page);
    setLoading(true);

    const url = `${SERVER_URL}/${ADMIN_API}/search-participants?id=${props.hunt.hunt._id}&q=${value}&page=1`;
    const headers = { "auth-token": props.user.token };

    const data = await api.fetchData("GET", url, headers, null);
    if (data.players) {
      setParticipants(data.players);
    } else {
      window.alert("Player not found");
    }
    setLoading(false);
  };

  return (
    <div className="english inpageSection">
      <h1 style={{ margin: "5px" }}>Participants</h1>
      {participants && (
        <DownloadCSV
          data={participants.map((s) => {
            s.members = s.count;
            s.tasks = props.hunt?.hunt?.submissions?.filter(
              (_s) => _s.name === s.name
            )?.length;
            // delete s._id;
            delete s.count;
            return s;
          })}
          filename="Participants.csv"
        />
      )}
      <hr />
      <TextField
        value={value}
        onChange={(e) => setValue(e.target.value)}
        fullWidth
        label={"Search User"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                color="primary"
                onClick={() => getParticipants(true)}
              >
                <SearchIcon />
              </IconButton>
              <IconButton
                edge="end"
                color="primary"
                onClick={() => {
                  // clear();
                  setValue("");
                  getParticipants(true, true);
                }}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div className="pagination">
        <button onClick={handlePrevClick} disabled={page === 1}>
          Previous
        </button>
        <div className="pages">
          {[...Array(totalPages)].map((_, index) => {
            const pageNum = index + 1;
            return (
              <button
                key={pageNum}
                onClick={() => handlePageClick(pageNum)}
                className={page === pageNum ? "active" : ""}
              >
                {pageNum}
              </button>
            );
          })}
        </div>

        <button onClick={handleNextClick} disabled={page === totalPages}>
          Next
        </button>
      </div>
      {loading ? (
        <div
          style={{
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <ResponsiveTable
            removeParticipant={(item) => {
              let confirmed = window.confirm(
                `Are you sure you want to delete ${item.name}?`
              );
              if (confirmed) {
                removeParticipant(item);
              }
            }}
            submissions={submissions}
            removeLoading={removeLoading}
            hunt={props.hunt.hunt}
            tasks={props.hunt.tasks}
            headers={tableHeaders}
            data={participants}
            tableName="participants"
            addBonus={(player, bonus) => addBonus(player, bonus)}
            bonusLoading={bonusLoading}
            bonusPlayer={bonusPlayer}
            bonus={bonus}
            onChangeBonus={(e, player) => onChangeBonus(e, player)}
            isTabletOrMobile={props.isTabletOrMobile}
          />
        </>
      )}
      <div className="pagination">
        <button onClick={handlePrevClick} disabled={page === 1}>
          Previous
        </button>
        <div className="pages">
          {[...Array(totalPages)].map((_, index) => {
            const pageNum = index + 1;
            return (
              <button
                key={pageNum}
                onClick={() => handlePageClick(pageNum)}
                className={page === pageNum ? "active" : ""}
              >
                {pageNum}
              </button>
            );
          })}
        </div>
        <button onClick={handleNextClick} disabled={page === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
}
